<template>
    <div id="my-member" class="page">
      <b-card class="mb-3" v-if="member">
        <b-row>
          <b-col>
            <h5 class="m-0">{{ member.fullName() }}</h5>
            <div v-if="userPlatformStatus">
              <span v-if="userPlatformStatus.platformStatus === 'offline'" class="text-secondary">{{ userPlatformStatus.platformStatus }}</span>
              <span v-if="userPlatformStatus.platformStatus === 'online'" class="text-success">{{ userPlatformStatus.platformStatus }}</span>
              <span v-if="userPlatformStatus.platformStatus === 'busy'" class="text-danger">{{ userPlatformStatus.platformStatus }}</span>
            </div>
          </b-col>
          <b-col class="text-right">
            <!--<b-button :disabled="userPlatformStatus.platformStatus !== 'online'" variant="outline-primary" size="sm"><BIconTelephoneFill/> Call</b-button>-->
          </b-col>
        </b-row>
      </b-card>
      <!--
      <div id="member-chat" class="mb-3">
        <b-card no-body class="mb-3">
          <Chat v-if="member" :seller="member" :user="user"/>
        </b-card>
      </div>
    -->
      <!--<TodoCard class="mb-3"/>-->
      <MemberCalls ref="memberCalls" :loggedUser="user" v-if="member" title="Recent Calls" :user="member" userType="member" handler="userCalls"/>
    </div>
</template>

<script>
import MemberCalls from './../components/Calls/Calls.Card.Template';
import Member from '@/modules/users/models/Member';
//import TodoCard from '@/modules/crm/components/Calendar/Todo.Card';
//import Chat from '@/common/components/Chat/Chat';
//import {BIconTelephoneFill} from 'bootstrap-vue';
export default {
    props: ['user'],
    components: {
      MemberCalls,
      //TodoCard,
      //BIconTelephoneFill,
      //Chat
    },
    data(){
        return {
          member: null
        };
    },

    methods:{

      getUser(){
        this.member = null;
        return this.$api.get(`user/${this.$route.params.userId}`).then(res => {
          this.member = new Member(res.data);
          this.$emit('set-sidebar',{active: "member", member: this.member});
        }).catch((e) => {console.log('cannot get user',e)});
      }
    },

    mounted(){
      this.getUser();
    },

    computed: {
      inCall(){
        return this.$store.getters['Callcenter/getInCall'];
      },
      /*
      liveUser(){
        return this.$store.getters['Callcenter/getUsers'].find(o => o.id === this.$route.params.userId);
      },
      */
      userPlatformStatus(){
        let data = this.$store.getters['Callcenter/getUsersOnlineInPlatform'].find(o => o.userId === this.$route.params.userId);
        return data ? data : {
          platformStatus: "offline"
        };
      }
    },

    watch: {
      "$route.params.userId": function(){
        this.getUser().then(() => {
          this.$refs['memberCalls'].userCalls().getCalls();
        });
      }
    }
};
</script>
