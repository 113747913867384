<template>
    <div id="calls-template">
      <b-card no-body class="card-custom card-custom-footer-pagination">
        <template #header>
          <b-row>
            <b-col><h6 class="mb-0">{{title}} ({{calls.total}})</h6></b-col>
            <b-col class="text-right">
              <slot name="links"/>
              <b-link @click="toggleFilters()">
                <b-badge v-if="countFilters > 0" variant="warning" class="float-right">{{ countFilters }}</b-badge>
                <BIconFilter font-scale="1.4"/>
              </b-link>
            </b-col>
          </b-row>
        </template>
        <div v-if="showFilters" class="calls-filters border-bottom" style="padding: 20px; background: #f5f5f5;">
          <b-row>
            <b-col>
              <b-form-input @change="updateCalls()" v-model="filters.query" type="text" placeholder="find..."></b-form-input>
            </b-col>
            <b-col v-if="canFilterByChannel()">
              <b-form-select @change="updateCalls()" v-model="filters.channels.selected" :options="filters.channels.options"></b-form-select>
            </b-col>
            <b-col>
              <b-form-select @change="updateCalls()" v-model="filters.logTypes.selected" :options="filters.logTypes.options"></b-form-select>
            </b-col>
            <b-col>
              <b-form-select @change="updateCalls()" v-model="filters.statuses.selected" :options="filters.statuses.options"></b-form-select>
            </b-col>
          </b-row>
        </div>
        <CallsTable v-if="!calls.isLoading" :items="calls" :channels="channels" :user="loggedUser" :handler="handler"/>
        <b-spinner v-else label="Loading..." small style="margin: 20px;"></b-spinner>
        <template v-if="showPagination && !calls.isLoading && calls.pagination.totalPages > 1 " #footer>
          <b-pagination v-if="!calls.paginationRouter" @change="calls.getCalls" v-model="calls.pagination.currentPageNo" :total-rows="calls.total" :per-page="calls.pagination.perPage" size="sm" align="fill"></b-pagination>
          <PaginationRouter v-else :pagination="calls.pagination" :linkGen="calls.paginationRouter"/>
        </template>
      </b-card>
    </div>
</template>

<script>
import CallsTable from './Calls.Table';
import PaginationRouter from '@/common/components/Pagination.Router';
import {BIconFilter} from 'bootstrap-vue';
export default {
    props: ['title','showPagination','user','userType','handler','perPage','loggedUser','channelId'],
    components: {
      CallsTable,
      PaginationRouter,
      BIconFilter
    },
    data(){
        return {
          showFilters: false,
          filters: {
            query: null,
            channels: {
              selected: null,
              options: []
            },
            logTypes: {
              selected: null,
              options: [
                {value: null, text: "choose type"},
                {value: "incoming", text: "Incoming"},
                {value: "outgoing", text: "Outgoing"}
              ]
            },
            statuses: {
              selected: null,
              options: [
                {value: null, text: "choose status"},
                {value: "pending", text: "Pending"},
                {value: "ongoing", text: "Ongoing"},
                {value: "hungup", text: "Hungup"},
                {value: "missed", text: "Missed"},
              ]
            }
          },
          calls: {
            isLoading: false,
            records: [],
            total: 0,
            pagination: {},
          }
        };
    },

    methods:{
      canFilterByChannel(){
        if(this.channelId){
          return false;
        }
        return true;
        /*
        let channelsFeature = this.loggedUser.getPackageFeature('channels');
        if(channelsFeature){
          if(channelsFeature.extra.maxChannels > 1){
            return true;
          }
        }
        return false;
        */
      },
      userCalls(){
        let handler = {
          paginationRouter: (pageNum) => {
            let query = this.getFiltersQuery();
            if(pageNum > 1){
                query.page = pageNum;
            }
            let route = "callcenter.myCalls";
            let params = {};
            if(this.userType === "member"){
              route = "callcenter.team.member"
              params = {userId: this.user.get('id')};
            }
            return this.$router.resolve({name: route,params: params,query: query}).href;
          },
          getCalls: (pageNo) => {
            let params = Object.assign({
              page: pageNo,
              perPage: this.perPage || 50,
              orderBy: "datetime",
              arrange: "desc"
            }, this.getFiltersQuery());
            this.calls.isLoading = true;
            return this.$api.get(`user/${this.user.get('id')}/calls`, {params: params}).then(res => {
              this.calls.isLoading = false;
              let {records, pagination, total} = res.data;
              this.calls.pagination = pagination;
              this.calls.total = total;
              this.calls.records = records;
            }).catch((e) => {
              this.calls.isLoading = false;
              console.log('cannot fetch calls', e)
            });
          }
        }
        this.calls.paginationRouter = handler.paginationRouter;
        this.calls.getCalls = handler.getCalls;
        return handler;
      },

      allCalls(){
        let handler = {
          paginationRouter: (pageNum) => {
            let query = this.getFiltersQuery();
            if(pageNum > 1){
                query.page = pageNum;
            }
            return this.$router.resolve({
                name: "callcenter.calls",
                query: query
            }).href;
          },
          getCalls: (pageNo) => {
            this.calls.isLoading = true;
            let params = Object.assign({
              page: pageNo,
              perPage: this.perPage || 50,
              orderBy: "datetime",
              arrange: "desc"
            }, this.getFiltersQuery());
            return this.$api.get('calls', {params: params}).then(res => {
              this.calls.isLoading = false;
              let {records, pagination, total} = res.data;
              this.calls.pagination = pagination;
              this.calls.total = total;
              this.calls.records = records;
            }).catch(() => {
              this.calls.isLoading = false;
              console.log('cannot fetch calls')
            });
          }
        }
        this.calls.paginationRouter = handler.paginationRouter;
        this.calls.getCalls = handler.getCalls;
        return handler;
      },

      channelCalls(){
        let handler = {
          paginationRouter: (pageNum) => {
            let query = Object.assign(this.getFiltersQuery(), {
              channel: this.channelId
            });
            if(pageNum > 1){
                query.page = pageNum;
            }
            return this.$router.resolve({
                name: "callcenter.calls",
                query: query
            }).href;
          },
          getCalls: (pageNo) => {
            this.calls.isLoading = true;
            let params = Object.assign({
              page: pageNo,
              perPage: this.perPage || 50,
              orderBy: "datetime",
              arrange: "desc"
            }, Object.assign(this.getFiltersQuery(), {
              channel: this.channelId
            }));
            return this.$api.get('calls', {params: params}).then(res => {
              this.calls.isLoading = false;
              let {records, pagination, total} = res.data;
              this.calls.pagination = pagination;
              this.calls.total = total;
              this.calls.records = records;
            }).catch(() => {
              this.calls.isLoading = false;
              console.log('cannot fetch calls')
            });
          }
        }
        this.calls.paginationRouter = handler.paginationRouter;
        this.calls.getCalls = handler.getCalls;
        return handler;
      },

      getFiltersQuery(){
        let query = {};
        if(this.filters.query){
          query.query = this.filters.query;
        }
        if(this.filters.channels.selected){
          query.channel = this.filters.channels.selected;
        }
        if(this.filters.logTypes.selected){
          query.logType = this.filters.logTypes.selected;
        }
        if(this.filters.statuses.selected){
          query.status = this.filters.statuses.selected;
        }
        return query;
      },

      toggleFilters(){
        this.showFilters = !this.showFilters;
      },

      populateFilters(){
        this.filters.channels.options = [{value: null, text: "choose channel"}];
        this.$api.get('channels').then(response => {
          this.filters.channels.options = this.filters.channels.options.concat(response.data.records.map(item => {
            return {value: item.id, text: item.name};
          }));
        })

      },

      updateCalls(){
        if(this.handler !== undefined){
          let handler = this[this.handler]();
          this.$router.push(handler.paginationRouter());
          handler.getCalls(1);
        }
      },
    },

    mounted(){
      if(this.handler !== undefined){
        this.filters.query = this.$route.query.query || null;
        this.filters.channels.selected = this.$route.query.channel || null;
        this.filters.logTypes.selected = this.$route.query.logType || null;
        this.filters.statuses.selected = this.$route.query.status || null;
        this[this.handler]().getCalls(this.$route.query.page || 1).then(() => {
          this.populateFilters();
        });
      }
    },

    watch: {
      "$route.query.page": function(pageNo){
        if(this.calls.paginationRouter){
          console.log("$route.query.page", pageNo);
          this[this.handler]().getCalls(pageNo);
        }
      }
    },

    computed: {
      channels(){
        return this.$store.getters['Callcenter/getChannels'];
      },
      countFilters(){
        let cnt = 0;
        if(this.filters.query) cnt++;
        if(this.filters.channels.selected) cnt++;
        if(this.filters.logTypes.selected) cnt++;
        if(this.filters.statuses.selected) cnt++;
        return cnt;
      }
    }
};
</script>
