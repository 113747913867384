<template>
  <b-row>
    <b-col cols="4" class="pt-2">
      Page {{ pagination.currentPageNo }} out of {{ pagination.totalPages }}
    </b-col>
    <b-col cols="8">
      <b-pagination-nav v-if="pagination.totalPages > 1"
          class="float-right"
          v-model="pagination.currentPageNo"
          :link-gen="linkGen"
          :number-of-pages="pagination.totalPages"
          :per-page="pagination.perPage"
          use-router
      ></b-pagination-nav>
    </b-col>
  </b-row>
</template>

<script>
export default {
    name: 'Pagination.Router',
    props: ['pagination','linkGen']
};
</script>
