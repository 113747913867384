<template>
  <div id="calls-table">
    <b-table class="table-calls" sticky-header responsive hover :items="calls.records" :fields="calls.fields" empty-text="no calls" show-empty>
      <template #cell(type_icon)="data">
        <!--
        <BIconTelephoneForward v-if="data.item.logType === 'outgoing'" variant="primary"/>
        <BIconTelephoneInbound v-if="data.item.logType === 'incoming'" variant="success"/>
        <BIconTelephoneX v-if="data.item.type === 'missed'" variant="danger"/>
      -->
      <BIconTelephoneInbound v-if="data.item.status === 'hungup'" variant="success"/>
        <BIconTelephoneX v-if="data.item.status === 'missed'" variant="danger"/>
      </template>
      <template #cell(channelId)="data">
        <b-link :to="{name: 'channel.board', params: {channelId: data.item.channelId}}">{{ getChannelName(data.item.channelId) }}</b-link>
      </template>
      <template #cell(name)="data">
        <b-link v-if="data.item.name">{{data.item.name}}</b-link>
        <span v-else>{{data.item.visitorId && data.item.visitorId.length > 20 ? data.item.visitorId.slice(0,20)+'...' : data.item.visitorId || "undefined"}}</span>
      </template>
      <template #cell(options)="data">
        <div class="text-right">
          <b-link v-b-tooltip.hover title="Messages" v-if="data.item.totalMessages > 0" class="mr-3 call-notif-badge-container">
            <b-badge variant="danger" class="call-notif-badge rounded-circle">{{data.item.totalMessages}}</b-badge>
            <BIconChatDots/>
          </b-link>
          <b-link v-if="user.hasPermission('rtc:call.notes.search') && data.item.totalNotes > 0" v-b-tooltip.hover title="Notes" :to="{name: 'callcenter.calls.call', params: {callId: data.item.id}}" class="mr-3 call-notif-badge-container">
            <b-badge variant="danger" class="call-notif-badge rounded-circle">{{data.item.totalNotes}}</b-badge>
            <BIconJournalText/>
          </b-link>
          <b-link v-if="data.item.isAppointment" class="mr-3"><BIconCalendar3Event/></b-link>
          <b-link v-b-tooltip.hover title="Details" :to="{name: 'callcenter.calls.call', params: {callId: data.item.id}}"><BIconInfoCircleFill/></b-link>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import {formatDate} from '@/common/utils/common';
import {
  //BIconTelephoneForward,
  BIconTelephoneInbound,BIconTelephoneX,BIconInfoCircleFill,BIconCalendar3Event,
  BIconJournalText,BIconChatDots
} from 'bootstrap-vue';
export default {
    props: ['title','perPage','user','items','channels','handler'],
    components: {
      //BIconTelephoneForward,
      BIconTelephoneInbound,
      BIconTelephoneX,
      BIconInfoCircleFill,
      BIconCalendar3Event,
      BIconJournalText,
      BIconChatDots
    },
    data(){
        return {
          calls: {
            isLoading: true,
            fields: [],
            records: [],
            total: 0,
            pagination: {
              currentPageNo: 1
            }
          }
        };
    },

    methods:{
      getChannelName(id){
        let channel = this.channels.find(o => o.id === id);
        if(!channel){
          return id;
        }
        return channel.name;
      },

      getFields(){
        let fields = [
          {
            key: 'type_icon',
            label: "#"
          },
          {
            key: 'name',
            label: "Visitor/Contact"
          },
        ];

        /*
        let channelsFeature = this.user.getPackageFeature('channels');
        if(channelsFeature){
          if(channelsFeature.extra.maxChannels > 1){
            fields.push({key: 'channelId',label: "Channel"});
          }
        }
        */

        fields.push({key: 'channelId',label: "Channel"});

        if(this.handler !== "userCalls"){
          if(this.user.hasPermission('users:user.search')){
            fields.push({key: 'calleeId', label: "Callee",
              formatter: value => {
                let user = this.getUsers.find(o => o.id === value);
                return value === null ? '-' : (user ? user.fullName : '-');
              }
            });
          }
        }

        return fields.concat([
          {
            key: 'logType',
            label: "Type"
          },
          {
            key: 'status',
            label: "Status"
          },
          {
            key: 'duration',
            label: "Duration",
            formatter: value => {
              return value === null ? '-' : new Date(value * 1000).toISOString().substr(11, 8);
            }
          },
          {
            key: 'createdAt',
            label: "Date",
            formatter: value => {
              return formatDate(value, 'DD/MM HH:mm');
            }
          },
          {
            key: 'options',
            label: ""
          },
        ])
      }
    },

    mounted(){
      let {records} = this.items;
      this.calls.records = records;
      this.calls.fields = this.getFields();
    },

    computed: {
      getUsers(){
        return this.$store.getters['Callcenter/getUsers'];
      }
    }
};
</script>
